import { createSlice } from "@reduxjs/toolkit";

const languages = {
  EN: {
    hero: {
      mainTitle: "Professional call-center services.",
      secondTitle: "Boosting your business!",
    },
    services: {
      sectionTitle: "Our Services",
      customerCare: "Customer Care",
      contactCenter: "Contact Center",
      backOfficeSupport: "Back Office Support",
      helpDesk: "HelpDesk",
      sectionMessage_1: "Choose the services that fit your company and",
      sectionMessage_1_anchor: "contact us!",

      sectionMessage_2: "InfraGroup Multi Service Provider.",
    },
    customers: {
      sectionTitle: "Our Customers",
    },
    contact: {
      sectionTitle: "Contact Us!",
      form: {
        subject: "Subject",
        name: "Name",
        phone: "Phone number",
        message: "Message",
        send: "Send",
      },
    },
  },
  RO: {
    hero: {
      mainTitle: "Servicii call-center profesionale.",
      secondTitle: "Boosting your business!",
    },
    services: {
      sectionTitle: "Serviciile Noastre",
      customerCare: "Asistenta Clienti",
      contactCenter: "Contact Center",
      backOfficeSupport: "Back Office Support",
      helpDesk: "HelpDesk",
      sectionMessage_1: "Alege serviciile de care compania ta are nevoie și",
      sectionMessage_1_anchor: "contactează-ne!",
      sectionMessage_2: "InfraGroup Multi Service Provider.",
    },
    customers: {
      sectionTitle: "Clienții noștri",
    },
    contact: {
      sectionTitle: "Contactează-ne!",
      form: {
        subject: "Subiect",
        name: "Nume",
        phone: "Telefon",
        message: "Mesaj",
        send: "Trimite",
      },
    },
  },
};

export const languageSlice = createSlice({
  name: "languages",
  initialState: {
    languageSet: "RO",
    language: languages.RO,
  },
  reducers: {
    swapLanguage: (state, action) => {
      state.languageSet = action.payload;
      action.payload === "EN"
        ? (state.language = languages.EN)
        : (state.language = languages.RO);
    },
  },
});

export default languageSlice.reducer;
export const { swapLanguage } = languageSlice.actions;
