import React from "react";
import MainTitlte from "./MainTitlte";
import { Button, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { swapLanguage } from "../../redux/slices/languageSlice";

export default function Hero() {
  const languageSet = useSelector((state) => state.languages.languageSet);
  const dispatch = useDispatch();
  const secondTitle = useSelector(
    (state) => state.languages.language.hero.secondTitle
  );
  return (
    <div className="h-[100vh]  pt-20">
      <div className="absolute top-5 left-[50%] translate-x-[-50%] lg:left-[80%] lg:translate-x-0  lg:right-[20] ">
        <Button
          onClick={() => dispatch(swapLanguage("RO"))}
          variant={languageSet === "RO" ? "contained" : "outlined"}
          color={"primary"}
          sx={{ marginRight: 5 }}
        >
          RO
        </Button>
        <Button
          onClick={() => dispatch(swapLanguage("EN"))}
          variant={languageSet === "EN" ? "contained" : "outlined"}
          color={"primary"}
        >
          EN
        </Button>
      </div>
      <div className="flex flex-col items-center relative z-20 ">
        {/* <h4 className="text-blue-600 font-semibold">
          InfraGroup Multi Service Provider
        </h4> */}
        <div className="mt-[3rem]">
          <img
            src={
              "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/infragroup-logo-rgb.webp"
            }
            alt=""
            className="w-[200px]"
          />
        </div>
        <div className="mt-[3rem] md:mt-0">
          <MainTitlte />
        </div>
        <p className="text-[1.2rem] md:text-[1.4rem] ">{secondTitle}</p>
        <div className="mt-[2rem] md:mt-[3rem]">
          <a href="#contact">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#52e8b3",
                borderRadius: "20px",
                fontWeight: "bold",
                padding: "15px 30px 15px 30px",
                textTransform: "none",
                fontSize: "0.9rem",
                letterSpacing: "2px",
              }}
              disableElevation
            >
              Contact
            </Button>
          </a>
        </div>
      </div>
      <div className="relative h-[500px] lg:h-[300px] w-100 -z-20">
        <div
          id="gradient-hero"
          className="hidden md:block left-0 top-[-100px] lg:top-[0] right-0 bottom-0 absolute rounded-tl-full rounded-tr-full"
        ></div>
        <img
          src={
            "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/Cube4%20-%20Transparent.webp"
          }
          alt=""
          className="absolute right-20 bottom-[5px] bigger-cube hidden lg:block"
        />
        <img
          src={
            "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/Cube8%20-%20Transparent.webp"
          }
          alt=""
          className="absolute left-20 bottom-[150px] smaller-cube hidden lg:block"
        />
      </div>
    </div>
  );
}
