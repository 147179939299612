import React from "react";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";

export default function Footer() {
  return (
    <div className="min-h-[12rem] lg:min-h-[7rem] bg-[#1f3365] flex flex-col lg:flex-row items-center justify-between w-full px-[10px] py-[20px] lg:py-0 lg:px-[40px]">
      <p className="flex items-center justify-center font-semibold">
        <span className=" lg:mr-[10px]">
          <PhoneAndroidIcon sx={{ color: "white", fontSize: "35px" }} />
        </span>
        <a
          className="text-white border-b-2 border-sky-500"
          href="tel: +40 31 229 7003"
        >
          +40 31 229 7003
        </a>
      </p>
      <p className="text-white font-semibold lg:text-[1.2rem] my-[1rem]">
        © InfraGroup@2022
      </p>
      <p className="flex items-center justify-center">
        <span className=" lg:mr-[10px]">
          <EmailIcon sx={{ color: "white", fontSize: "35px" }} />
        </span>
        <a
          className="text-white font-semibold border-b-2 border-sky-500"
          href="mailto:office@infragroup.ro"
        >
          office@infragroup.ro
        </a>
      </p>
    </div>
  );
}
