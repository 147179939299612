import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Customer from "./Customer";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";

const customers = [
  {
    name: "FAPACO",
    logo: "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/customers/fapaco.webp",
  },
  {
    name: "NOVUM",
    logo: "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/customers/novum.webp",
  },
  {
    name: "MEHEDINTI GAZ",
    logo: "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/customers/mehedinti-gaz.webp",
  },
  {
    name: "NORD GAZ",
    logo: "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/customers/nord-gaz.webp",
  },
  {
    name: "IFMA",
    logo: "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/customers/ifma-logo.webp",
  },
  {
    name: "IOWEMED",
    logo: "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/customers/medicover.webp",
  },
  {
    name: "MEDICOVER",
    logo: "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/customers/medicover.webp",
  },
  {
    name: "ANRE",
    logo: "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/customers/anre.webp",
  },
  {
    name: "KONE Ascensorul",
    logo: "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/customers/kone.webp",
  },
  {
    name: "NEXTGEN Communications",
    logo: "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/customers/nextgen.webp",
  },
];

export default function Customers() {
  const [customerCards, setCustomerCards] = useState([]);
  const [activeCustomers, setActiveCustomers] = useState([]);
  const [activeCustomersCount, setActiveCustomersCount] = useState(0);
  const [hasFinished, setHasFinished] = useState(false);
  const contentLanguages = useSelector(
    (state) => state.languages.language.customers
  );
  const generateCustomers = () => {
    const generatedCustomerCards = customers.map((customer, index) => (
      <Customer key={customer.name} name={customer.name} logo={customer.logo} />
    ));
    return generatedCustomerCards;
  };

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    customerCards.length === 0 && setCustomerCards(generateCustomers());
    if (customerCards.length > 0) {
      setTimeout(
        () => {
          setActiveCustomers(
            customerCards.slice(activeCustomersCount, activeCustomersCount + 3)
          );
        },
        activeCustomersCount === 0 ? 0 : 5000
      );
    }
  }, [customerCards, activeCustomersCount]);

  useEffect(() => {
    if (
      customerCards.length % 3 != 0 &&
      activeCustomersCount === customerCards.length - (customerCards.length % 3)
    ) {
      setActiveCustomersCount((state) => (state += 1));
      setHasFinished(true);
      return;
    } else if (hasFinished) {
      setActiveCustomersCount(0);
      setHasFinished(false);
      return;
    }
    activeCustomers.length > 0 &&
      setActiveCustomersCount((state) => (state += 3));
  }, [activeCustomers]);

  return (
    <div
      data-aos="fade-in"
      data-aos-duration="1500"
      className="w-full mx-auto relative mt-[7rem] h-[100vh] xl:h-[60vh] flex flex-col justify-center items-center overflow-hidden"
    >
      <h2 className="text-center text-[2rem] font-semibold mb-[5rem] absolute top-0">
        {contentLanguages.sectionTitle}
      </h2>
      <div className="w-[75%] mx-auto">
        <div
          className={`flex flex-col mx-auto xl:flex-row ${
            activeCustomers.length < 3
              ? "justify-center items-center gap-24"
              : "justify-between items-center gap-10 xl:gap-0"
          }`}
        >
          {activeCustomers}
        </div>
      </div>
    </div>
  );
}
