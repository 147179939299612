import { Button, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import emailjs from "emailjs-com";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import "aos/dist/aos.css";
import SendIcon from "@mui/icons-material/Send";

import { useSelector } from "react-redux";
import { useRef } from "react";
import { forwardRef } from "react";
import { useFormik } from "formik";

export default function Contact() {
  const [isLoading, setIsLoading] = useState(false);
  const [timeoutFormCounter, setTimeoutFormCounter] = useState(0);
  const [showTimeoutText, setShowTimeoutText] = useState(false);
  const timeoutMessage = `Mai puteti trimite un mesaj in ${timeoutFormCounter} secunde.`;
  const formRef = useRef();
  const languageSet = useSelector((state) => state.languages.languageSet);

  const contactValidationSchema = yup.object().shape({
    email: yup
      .string()
      .max(
        30,
        languageSet === "RO"
          ? "Introduceti o adresa de e-mail valida."
          : "Type a valid email address."
      )
      .email(
        languageSet === "RO"
          ? "Introduceti o adresa de e-mail valida."
          : "Type a valid email address."
      )
      .required(
        languageSet === "RO"
          ? "Acest camp este obligatoriu."
          : "This field is mendatory."
      ),

    name: yup
      .string()
      .min(
        3,
        languageSet === "RO"
          ? "Numele trebuie sa contina minim 3 caractere."
          : "The name must have at least 3 characters."
      )
      .max(
        20,
        languageSet === "RO"
          ? "Numele nu poate sa depaseasca 20 caractere."
          : "The name can't exceed 20 characters."
      )
      .required(
        languageSet === "RO"
          ? "Acest camp este obligatoriu."
          : "This field is mendatory."
      ),
    subject: yup
      .string()
      .min(
        3,
        languageSet === "RO"
          ? "Subiectul trebuie sa contina minim 3 caractere."
          : "The subject must have at least 3 characters."
      )
      .max(
        50,
        languageSet === "RO"
          ? "Subiectul nu poate sa depaseasca 50 caractere."
          : "The subject can't exceed 50 characters."
      )
      .required(
        languageSet === "RO"
          ? "Acest camp este obligatoriu."
          : "This field is mendatory."
      ),
    message: yup
      .string()
      .min(
        15,
        languageSet === "RO"
          ? "Mesajul trebuie sa contina minim 15 caractere."
          : "The message must have at least 15 characters."
      )
      .max(
        500,
        languageSet === "RO"
          ? "Mesajul nu poate sa depaseasca 500 caractere."
          : "The message can't exceed 500 characters."
      )
      .required(
        languageSet === "RO"
          ? "Acest camp este obligatoriu."
          : "This field is mendatory."
      ),

    phone: yup
      .string()
      .min(
        10,
        languageSet === "RO"
          ? "Numar de telefon invalid."
          : "Invalid phone number."
      )
      .max(
        10,
        languageSet === "RO"
          ? "Numar de telefon invalid."
          : "Invalid phone number."
      )
      .required(
        languageSet === "RO"
          ? "Acest camp este obligatoriu."
          : "This field is mendatory."
      ),
  });

  const formik = useFormik({
    initialValues: {
      subject: "",
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: contactValidationSchema,
    onSubmit: sendEmail,
  });

  const [messageSent, setMessageSent] = useState("");
  const MySwal = withReactContent(Swal);
  const contentLanguages = useSelector(
    (state) => state.languages.language.contact
  );

  const timeoutForm = () => {
    localStorage.setItem("hasFormTimeout", 1);
    let count = 30;
    setInterval(() => {
      if (count <= 30 && count >= 0) setTimeoutFormCounter(count);
      if (count === 0) setShowTimeoutText(false);
      count--;
    }, 1000);
  };

  useEffect(() => {
    AOS.init();
    // emailjs.init("miwZ_DKGDjMJlWlTn");
  }, []);

  const Loader = () => {
    return (
      <div className="sticky w-full h-[100vh]  bg-[#97e9d041] top-0 left-0 flex justify-center items-center z-[5000]">
        <CircularProgress />
      </div>
    );
  };

  function sendEmail() {
    if (timeoutFormCounter > 0) {
      setShowTimeoutText(true);
      return;
    }
    setShowTimeoutText(false);

    timeoutForm();
    console.log(formRef.current);
    emailjs
      .sendForm(
        "service_h4gd8ha",
        "template_v369ow9",
        formRef.current,
        "miwZ_DKGDjMJlWlTn"
      )
      .then(
        (result) => {
          formik.resetForm();
          MySwal.fire({
            title: (
              <strong>
                {languageSet === "RO"
                  ? "Mesajul a fost trimis."
                  : "We received your message!"}
              </strong>
            ),
            html: (
              <p>
                {languageSet === "RO"
                  ? "Vom raspunde in cel mai scurt timp."
                  : ""}
              </p>
            ),
            icon: "success",
            confirmButtonColor: "#52e8b3",
          });
          console.log(result);
        },
        (error) => {
          MySwal.fire({
            title: <strong>A aparut o problema.</strong>,
            html: <p>Va rugam reincercati.</p>,
            icon: "error",
            confirmButtonColor: "#52e8b3",
          });
          console.log(error);
        }
      );
  }

  return (
    <div
      className="h-[140vh] xs:h-[100vh] lg:h-[120vh] relative w-100 mt-[5rem] p-[10px] md:p-[50px]"
      id="contact"
    >
      {/* <Loader /> */}
      <h2
        data-aos="fade-in"
        data-aos-duration="1000"
        className="text-center text-[2rem] font-semibold mb-[10rem]"
      >
        {contentLanguages.sectionTitle}
      </h2>
      <div className="flex items-center justify-around ">
        <img
          src={
            "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/mail.webp"
          }
          data-aos="fade-right"
          data-aos-duration="1000"
          className="w-[400px] hidden lg:block"
        />
        <div
          className="w-[700px] h-[350px] p-[10px] md:p-[50px] rounded-3xl relative flex flex-col justify-center items-center mt-[5rem]"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div
            className="absolute top-0 left-0 w-full h-full"
            style={{
              backgroundImage: `url('https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/vecteezy_abstract-botanical-and-organic-leaves-shape-template-with_.webp')`,
              backgroundSize: "cover",
            }}
          ></div>
          <div className="relative z-20 p-[20px] border-[6px] border-[#52e8b3] shadow-2xl rounded-xl">
            <div className="bg-white absolute top-0 left-0 w-full h-full opacity-[0.95] -z-10"></div>
            <form
              ref={formRef}
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <TextField
                value={formik.values.subject}
                onChange={formik.handleChange}
                error={formik.touched.subject && Boolean(formik.errors.subject)}
                helperText={formik.touched.subject && formik.errors.subject}
                id="subject"
                name="subject"
                label={contentLanguages.form.subject}
                variant="standard"
                InputProps={{
                  style: {
                    fontSize: "1.5rem",
                    width: "85%",
                    marginBottom: "10px",
                  },
                }}
                type="text"
                InputLabelProps={{ style: { fontSize: "15px" } }}
                sx={{ width: "100%" }}
              />

              <TextField
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                name="name"
                id="name"
                label={contentLanguages.form.name}
                variant="standard"
                InputProps={{
                  style: {
                    fontSize: "1.5rem",
                    width: "90%",
                    marginBottom: "10px",
                  },
                }}
                InputLabelProps={{ style: { fontSize: "15px" } }}
                sx={{ width: "100%" }}
              />

              <TextField
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                name="email"
                id="email"
                label="Email"
                variant="standard"
                InputProps={{
                  style: {
                    fontSize: "1.5rem",
                    width: "95%",
                    marginBottom: "10px",
                  },
                }}
                InputLabelProps={{ style: { fontSize: "15px" } }}
                sx={{ width: "100%" }}
              />

              <TextField
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                name="phone"
                id="phone"
                label={contentLanguages.form.phone}
                variant="standard"
                InputProps={{
                  style: {
                    fontSize: "1.5rem",
                    width: "100%",
                    marginBottom: "10px",
                  },
                }}
                InputLabelProps={{ style: { fontSize: "15px" } }}
                sx={{ width: "100%" }}
              />

              <TextField
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
                name="message"
                id="message"
                multiline
                label={contentLanguages.form.message}
                rows="3"
                variant="outlined"
                InputProps={{
                  style: {
                    width: "100%",
                    marginBottom: "10px",
                  },
                }}
                sx={{ width: "100%", marginTop: "40px" }}
              />
              {showTimeoutText > 0 && (
                <p className="text-[1.2rem] text-[#52e8b3]">{timeoutMessage}</p>
              )}
              <Button
                disabled={timeoutFormCounter > 0}
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#52e8b3",
                  borderRadius: "20px",
                  fontWeight: "bold",
                  padding: "15px 30px 15px 30px",
                  textTransform: "none",
                  fontSize: "0.9rem",
                  letterSpacing: "2px",
                  marginTop: "3rem",
                }}
                disableElevation
                endIcon={<SendIcon />}
              >
                {contentLanguages.form.send}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
