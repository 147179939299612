import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Customers from "../Customers/Customers";
import Hero from "../Hero/Hero";
import Services from "../Services/Services";
import Footer from "../Footer/Footer";
import { useEffect } from "react";
import ArrowUp from "./ArrowUp";

export default function Landing() {
  return (
    <div>
      <ArrowUp />
      <Hero />
      <Services />
      <Customers />
      <Contact />
      <Footer />
    </div>
  );
}
