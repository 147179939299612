import React from "react";

export default function Customer({ name, logo }) {
  return (
    <div className=" h-[130px] w-[250px] xl:h-[180px] xl:w-[330px] p-[10px] shadow-2xl bg-[#ffffff] rounded-2xl  hover:shadow-[#b8c3e8]">
      <div className="w-[80%] mx-auto h-full flex items-center justify-center">
        <img src={logo} alt="" className="h-[40px] mx-auto" />
      </div>
      {/* <div className="flex justify-center items-center h-[70%]">
        <h2 className="font-semibold text-center  text-[1.5rem]">{name}</h2>
      </div> */}
    </div>
  );
}
