import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

export default function Services() {
  const Service = ({ image, title, delay, animation, duration }) => {
    return (
      <li
        className="flex flex-col lg:flex-row items-center justify-center bg-white lg:bg-transparent rounded-full lg:rounded-none shadow-2xl lg:shadow-none h-[15rem] w-[15rem] lg:h-auto lg:w-auto mb-[2rem] lg:mb-0"
        data-aos={animation}
        data-aos-duration={duration}
        data-aos-delay={delay}
      >
        <img
          src={image}
          className="w-[80px] mb-[1rem] lg:mb-0 lg:mr-[1.5rem]"
          alt=""
        />
        <p className="font-semibold text-[1.3rem]">{title}</p>
      </li>
    );
  };

  const servicesLanguage = useSelector(
    (state) => state.languages.language.services
  );

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="w-[90%] lg:h-[60vh] mx-auto relative rounded-xl p-[20px] mt-[10rem] 3xl:mt-0">
      <div
        data-aos="fade-zoom-in"
        data-aos-duration="1000"
        className="hidden lg:block w-full h-full bg-[#dbe3f3] absolute top-0 left-0 rounded-xl services-blur shadow-lg z-[-100]"
      ></div>
      <img
        data-aos="fade-left"
        data-aos-duration="1000"
        src={
          "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/Other13.webp"
        }
        alt="illustration"
        className="hidden lg:block absolute h-[200px] 3xl:h-[250px] right-[-50px] top-[-80px]"
      />
      <h2 className="text-center font-semibold text-[2rem] mb-[5rem] lg:mb-0">
        {servicesLanguage.sectionTitle}
      </h2>
      <div className="flex justify-around items-center h-[70%]">
        <ul className="flex flex-col lg:flex-row items-center justify-around w-full">
          <Service
            image={
              "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/services/customer-service-agent.webp"
            }
            title={servicesLanguage.customerCare}
            animation="fade-right"
            duration="350"
            delay="0"
          />
          <Service
            image={
              "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/services/telephone.webp"
            }
            title={servicesLanguage.contactCenter}
            animation="fade-right"
            duration="350"
            delay="350"
          />
          <Service
            image={
              "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/services/project-management.webp"
            }
            title={servicesLanguage.backOfficeSupport}
            animation="fade-right"
            duration="350"
            delay="700"
          />
          <Service
            image={
              "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/services/help-desk.webp"
            }
            title={servicesLanguage.helpDesk}
            animation="fade-right"
            duration="350"
            delay="1050"
          />
        </ul>
      </div>
      <div className="text-center hidden lg:block">
        <p className="mb-[0.5rem] text-[1.2rem] text-gray-600 ">
          {servicesLanguage.sectionMessage_1} <span> </span>
          <a href="#contact" className="border-b-[2px] border-[#52e8b3]">
            {servicesLanguage.sectionMessage_1_anchor}
          </a>
        </p>
      </div>
    </div>
  );
}
