import logo from "./logo.svg";
import "./App.css";
import Landing from "./components/Landing/Landing";
import Contact from "./components/Contact/Contact";
import { Provider } from "react-redux";
import store from "./redux/store/store";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Landing />
      </Provider>
    </div>
  );
}

export default App;
