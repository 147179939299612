import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

let count = 0;

export default function MainTitlte() {
  const [content, setContent] = useState("");
  const [displayType, setDisplayType] = useState("true");
  const mainText = useSelector(
    (state) => state.languages.language.hero.mainTitle
  );
  const [animationFinished, setAnimationFinished] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimationFinished(true);
      console.log("finished");
    }, mainText.length * 150);
    const interval = setInterval(() => {
      if (count == mainText.length) {
        clearInterval(interval);
        setDisplayType(false);
      }
      if (count <= mainText.length) {
        setContent(mainText.slice(0, count));
        count += 1;
      }
    }, 150);
  }, []);

  useEffect(() => {
    if (animationFinished) {
      setContent(mainText);
    }
  }, [mainText]);

  return (
    <div className="flex items-center text-center ">
      {content.length < 1 && (
        <h1 className="text-[4rem] font-bold my-5 opacity-0">{mainText}</h1>
      )}
      <h1 className="text-[1.3rem] xs:text-[1.5rem] md:text-[3rem] xl:text-[4rem] font-bold my-5">
        {content}
      </h1>
      {displayType && content.length > 0 && (
        <span id="type" className="text-[2.5rem] ml-2">
          |
        </span>
      )}
    </div>
  );
}
