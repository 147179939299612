import React, { useEffect, useState } from "react";

export default function ArrowUp() {
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => setWindowHeight(window.scrollY));
  }, []);

  return (
    <div
      onClick={() => window.scrollTo(0, 0)}
      className={`${
        windowHeight > 1000 ? "arrow-fade-in" : "arrow-fade-out"
      } hidden fixed right-[5%] bottom-[12%] bg-[rgb(139,216,189)] w-[60px] h-[60px] md:w-[90px] md:h-[90px] items-center justify-center rounded-full shadow-2xl cursor-pointer z-[1000]`}
    >
      <img
        className="w-[30px] md:w-[50px] rotate-[-45deg]"
        src={
          "https://infraspaces.ams3.digitaloceanspaces.com/Infragroup_media/landing/arrow.webp"
        }
        alt=""
      />
    </div>
  );
}
